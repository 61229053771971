<template>
  <div id="menu">
    <div v-if="loading" class="loading-page">
      <div class="loading-spinner">
        <img src="~$images/profile2/loadingpic2.gif" alt="Loading..." />
      </div>
    </div>
    <div v-else>
    <a class="profile-link" :href="`https://liff.line.me/1653951718-rxQYeq3a?reset=true&group_id=${profile.groupId}`">
      <img :src="`${profile.pictureUrl}`" alt="profile" />
    </a>
    <header class="header">
      <img src="~$images/logo-but-small.png" alt="logo" class="logo" />
      <nav>
        <a @click="updatePage('index')"
           v-bind:class="{ active: page === 'index' }">案件首頁</a>
        <a @click="updatePage('lists')"
           v-bind:class="{ active: page === 'lists' }">全部案件</a>
        <a href="https://liff.line.me/1653951718-07N1ya38">案件商店</a>
      </nav>
    </header>
    <Banner :showStoryModal="showStoryModal"/>
    <Ad :memberId="profile.userId"/>
    <aside class="filter-stories">
      <b-checkbox v-model="onlyShowOwnStories" native-value="顯示擁有案件">
        擁有案件
      </b-checkbox>
      <b-checkbox v-model="onlyShowNotFinishedStories" native-value="顯示未破案案件">
        未破案案件
      </b-checkbox>
    </aside>
    <aside class="search-stories">
      <b-field>
        <b-select placeholder="卷宗整理員"
                  v-model="selectedAuthorId" size="is-small">
          <option
            v-for="author in authors"
            :value="author.id"
            :key="author.id">
            {{ author.name }}
          </option>
        </b-select>
      </b-field>
      <b-field>
        <b-select placeholder="類別"
                  v-model="selectedStoryTagId"
                  :input="onStoryTagChange()"
                  size="is-small">
          <option
            v-for="storyTag in storyTags"
            :value="storyTag.id"
            :key="storyTag.id">
            {{ storyTag.name }}
          </option>
        </b-select>
      </b-field>
      <b-field>
        <b-select placeholder="難度"
                  v-model="selectedDifficulty"
                  size="is-small">
          <option
            v-for="difficulty in difficultys"
            :value="difficulty.id"
            :key="difficulty.id">
            {{ difficulty.name }}
          </option>
        </b-select>
      </b-field>
    </aside>
    <main class="content">
      <section v-for="(cate, index) in catesWithStories" :key="'cate' +index">
        <div v-if="cate.stories && (filterStories(cate.stories)).length > 0">
          <h3>{{ cate.name }} <a v-if="cate.buyUrl && cate.stories.find(story => !memberOwnStoryIds.includes(story.id))"
                                 :href="cate.buyUrl + '&group_id=' + profile.groupId + '&member_line_id=' + profile.userId">立即購買季票</a></h3>
          <b-carousel-list :data="filterStories(cate.stories)" :items-to-show="itemsToShow">
              <template #item="story">
                <StoryCard :story="story"
                           :isOwnStory="isOwnStory(story)"
                           :isfinished="isfinished(story)"
                           :showStoryModal="showStoryModal"/>
              </template>
          </b-carousel-list>
          <hr>
        </div>
      </section>
    </main>
    <footer>
      <section>
        <p>序號兌換</p>
        <b-field>
          <b-input v-model="token"></b-input>
        </b-field>
        <button @click="submitForm" type="button" class="btn">送出</button>
      </section>
    </footer>
    <StoryModal :isStoryModalActive="isStoryModalActive"
                v-on:closeStoryModalActive="closeStoryModalActive"
                :story="currentStory"
                :profile="profile"
                :isAvailablePlay="isAvailablePlay(currentStory)"
                :isAvailableBuy="isAvailableBuy(currentStory)"
                :updateOwnStoryId="updateOwnStoryId"/>
  </div>
 </div>
</template>

<script>
  const STORY_URL = 'https://judicial-intern.dmmt.design/api/v4/stories';
  const AD_INTERACTIONS_URL = 'https://judicial-intern.dmmt.design/api/v4/ad_interactions';
  const AUTHOR_URL = 'https://judicial-intern.dmmt.design/api/v4/authors';
  const STORY_TAG_URL = 'https://judicial-intern.dmmt.design/api/v4/story_tags';

  import axios from "axios";
  import APIClient from "@/api";
  import StoryModal from "@/components/menu/components/story-modal.vue";
  import StoryCard from "@/components/menu/components/story-card.vue";
  import Banner from "@/components/menu/components/banner.vue";
  import Ad from "@/components/menu/components/ads.vue";

  export default {
    components: { StoryModal, StoryCard, Banner, Ad },
    data(){
      return {
        loading: true,
        itemsToShow: 2.2,
        catesWithStories: [],
        adsStories: [],
        isStoryModalActive: false,
        currentStory: {},
        profile: {},
        page: '',
        onlyShowOwnStories: false,
        onlyShowNotFinishedStories: false,
        groupOwnStoryIds: [],
        memberOwnStoryIds: [],
        token: '',
        finishedStoryIds: [],
        authors: [{ id: 0, name: '全卷宗整理員' }],
        storyTags: [{ id: 0, name: '全類別' }],
        difficultys: [{ id: 0, name: '全難度' }, { id: 1, name: '1' }, { id: 2, name: '2' },
                     { id: 3, name: '3' }, { id: 4, name: '4' }, { id: 5, name: '5' }],
        selectedStoryTagId: 0,
        selectedAuthorId: 0,
        selectedDifficulty: 0
      }
    },
    async created() {
      let viewportInnerWidth = window.innerWidth;
      if (viewportInnerWidth < 550) {
        this.itemsToShow = 2.2;
      } else if (viewportInnerWidth < 850) {
        this.itemsToShow = 3.8;
      } else {
        this.itemsToShow = 5.2;
      }
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.get('reset')) {
        if (urlParams.get('group_id')) {
          localStorage.setItem('groupId', urlParams.get('group_id'));
        } else {
          localStorage.removeItem('groupId');
        }
        window.history.pushState(null, null, window.location.pathname);
      }
      try {
        const liffId = '1653951718-5b4qNxBM';
        await window.liff.init({ liffId })
                    .catch((err) => {
                     console.log("初始化錯誤", err.message);
                      if (this.group_id === 'U016222f6eeee6bc349074ce00e46aa37') { alert(err.message); }
                      throw err;
                    })
        if (!window.liff.isLoggedIn()) {
          alert("尚未登入");
         return window.liff.login();
        }
        const context = await window.liff.getContext();
        const userId = context.userId;
        const groupId = localStorage.getItem('groupId') || userId;
        // const userId = 'U016222f6eeee6bc349074ce00e46aa37',
        //       groupId = '1';
        this.profile = await this.getProfile(userId, groupId);
      } catch(err) {
        console.log(err);
        console.log(`遊戲服務中斷: ${err.message}`);
        window.liff.closeWindow();
      }
      const api = `https://judicial-intern.dmmt.design/api/v4/profiles/${this.profile.userId}?&groupId=${this.profile.groupId}`;
      await axios.get(api).then(res => {
        const data = res.data;
        this.groupOwnStoryIds = data.group.ownStoryIds || [];
        this.memberOwnStoryIds = data.member.ownStoryIds || [];
        this.finishedStoryIds = data.member.finishedStoryIds || [];
      });
      await axios.get(AUTHOR_URL).then(res => { this.authors = this.authors.concat(res.data.authors) });
      await axios.get(STORY_TAG_URL).then(res => {this.storyTags = this.storyTags.concat(res.data.storyTags) });
      this.updatePage(this.$route.params.page);
      this.loading = false;
    },
    methods: {
      async getProfile(userId, groupId) {
        let profile = (await APIClient.getmemberInfo(userId)).data;
        profile['groupId'] = groupId;
        return profile;
      },
      showStoryModal(story) {
        this.currentStory = story;
        this.isStoryModalActive = true;
      },
      updateOwnStoryId(storyId) {
        this.memberOwnStoryIds.push(storyId);
        this.groupOwnStoryIds.push(storyId);
      },
      onStoryTagChange() {
        if (this.selectedStoryTagId === 0) { return; }

        axios.put(`${AD_INTERACTIONS_URL}/${this.selectedStoryTagId}`);
      },
      isOwnStory(story) {
        return this.groupOwnStoryIds.includes(story.id) || this.memberOwnStoryIds.includes(story.id);
      },
      isfinished(story) {
        return this.finishedStoryIds.includes(story.id);
      },
      isAvailableBuy(story) {
        return !(this.memberOwnStoryIds.includes(story.id) || story.version === 'teach_version');
      },
      isAvailablePlay(story) {
        return this.isOwnStory(story) || ['free_version', 'teach_version'].includes(story.version);
      },
      filterStories(stories) {
        // 還是要 render 的時候再決定要不要顯示？
        let filteredStories = stories;
        if (this.onlyShowOwnStories) {
          filteredStories = filteredStories.filter(story => this.isOwnStory(story));
        }
        if (this.onlyShowNotFinishedStories) {
          filteredStories = filteredStories.filter(story => !this.isfinished(story));
        }
        if (this.selectedAuthorId !== 0) {
          filteredStories = filteredStories.filter(story => story.author.id === this.selectedAuthorId);
        }
        if (this.selectedStoryTagId !== 0) {
          filteredStories = filteredStories.filter(story => story.storyTags.some((storyTag) => storyTag.id === this.selectedStoryTagId));
        }
        if (this.selectedDifficulty !== 0) {
          filteredStories = filteredStories.filter(story => story.difficulty === this.selectedDifficulty);
        }
        return filteredStories;
      },
      async fetchStories(params = {}) {
        const data = await axios.get(STORY_URL, { params: params }).then(res => {
          return res.data;
        });
        return data.stories;
      },
      async updatePage(page) {
        this.page = page;
        if (page === 'lists') {
          this.catesWithStories = await this.fetchStories({ isAllStories: true, memberLineId: this.profile.userId });
        } else {
          this.catesWithStories = await this.fetchStories({ memberLineId: this.profile.userId });
        }
      },
      submitForm() {
        axios.post(`https://judicial-intern.dmmt.design/api/v2/members/${this.profile.userId}/tokens`, {
          token: this.token
        }).then(response => {
          let success = response.data.success,
              message = response.data.message;
          this.$buefy.dialog.alert({
            title: success ? '兌換成功' : '發生錯誤',
            message: message,
            type: 'is-success',
            hasIcon: false,
            ariaRole: 'alertdialog',
            ariaModal: true
          });
        })
      },
      closeStoryModalActive() {
        this.currentStory = {};
        this.isStoryModalActive = false;
      }
    }
  }
</script>

<style scoped lang="scss">
  #menu {
    min-height: 100vh;
    background: black;
    padding-bottom: 80px;

    .profile-link {
      display: block;
      text-align: center;
      position: absolute;
      top: 8px;
      right: 8px;

      img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
      }
    }

    header {
      max-width: 760px;
      margin: 0 auto 32px;
      width: 100%;
      text-align: center;

      img {
        max-width: 300px;
        width: 50%;
      }

      nav {
        margin-top: -24px;

        > a {
          display: inline-block;
          color: #fff;
          background: #a90000;
          border-radius: 8px;
          font-size: 14px;
          font-weight: bolder;
          margin-right: 8px;
          padding: 8px 16px;

          @media screen and (max-width: 400px) {
            font-size: 12px;
          }

          &:last-child {
            margin-right: 0; 
          }

          &.active {
            background: #790101;
          }
        }
      }
    }

    aside {
      margin: 24px auto;

      &.filter-stories {
        width: auto;
        margin: 0 80px;
        text-align: right;

        @media screen and (max-width: 400px) {
          margin: 8px 0;
        }
      }

      &.search-stories {
        width: auto;
        margin: 16px 80px 0;
        text-align: right;
        display: flex;
        justify-content: flex-end;

        @media screen and (max-width: 400px) {
          margin: 8px 0;
        }

        > div {
          margin-left: 8px;
        }
      }
    }

    main.content {
      width: auto;
      margin: 0 80px;

      @media screen and (max-width: 550px) {
        margin: 0;
      }

      section {
        margin-bottom: 16px;

        h3 {
          color: #fff;
          font-weight: bold;
          font-size: 24px;
          padding-left: 24px;

          a {
            font-size: 14px;
            color: #fff;
            background: #828282;
            padding: 4px 16px;
            margin-left: 16px;
            border-radius: 4px;
          }
        }

        hr {
          background: #4d4d4d;
          width: 95%;
          margin: 16px auto 24px;
        }
      }
    }

    footer {
      position: fixed;
      width: 100%;
      background: #262425;
      bottom: 0;
      color: #fff;
      padding: 8px 16px 16px;

      section {
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 480px;
        width: 100%;

        > p {
          font-size: 18px;
          font-weight: 900;
          margin-right: 8px;
        }

        .field {
          margin-bottom: 0;
          flex: 1;
        }

        button {
          background: #fff;
          font-size: 18px;
          padding: 4px 16px;
          border-radius: 4px;
          margin-left: 8px;
        }
      }
    }
  
      .loading-page {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 1);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 1000;
     }

    .loading-spinner img {
      width: 300px; 
      height: 300px;
      }
    }
</style>
